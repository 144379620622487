import React, { useState, useEffect } from "react";
import { Amplify } from "aws-amplify";
import { signOut } from "@aws-amplify/auth";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import awsconfig from "./aws-exports";
import NavigationBar from "./Componants/Navbar.jsx";
import logoGif from "./Assets/logo - Colored Background (1).png";
import './App.css';
import AdminPage from "./Componants/AdminPage.jsx";
import ProfileDetails from "./Componants/ProfileDetails.jsx";
import Overview from "./Componants/OverviewSection.jsx";
import IAM from "./Componants/IAM.jsx";
import Detection from "./Componants/Detection.jsx";
import InfrastrcutureProtection from "./Componants/InfrastrcutureProtection.jsx";
import DataProtection from "./Componants/DataProtection.jsx";
import IncidentResponse from "./Componants/IncidentResponse.jsx";

Amplify.configure(awsconfig);

const App = () => {
  const [user, setUser] = useState(null);

  const handleSignIn = () => {
    const signInUrl = `https://${awsconfig.oauth.domain}/login?client_id=${awsconfig.aws_user_pools_web_client_id}&response_type=code&scope=email+openid+profile&redirect_uri=${encodeURIComponent(awsconfig.oauth.redirectSignIn)}`;
    window.location.href = signInUrl;
  };

  const handleSignOut = () => {
    signOut().then(() => {
      sessionStorage.removeItem("idToken");
      setUser(null);
      const signOutUrl = `https://${awsconfig.oauth.domain}/logout?client_id=${awsconfig.aws_user_pools_web_client_id}&logout_uri=${encodeURIComponent(awsconfig.oauth.redirectSignOut)}`;
      window.location.href = signOutUrl;
    });
  };

  useEffect(() => {
    const fetchTokens = async (code) => {
      try {
        const response = await fetch(
          `https://${awsconfig.oauth.domain}/oauth2/token`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: new URLSearchParams({
              grant_type: "authorization_code",
              client_id: awsconfig.aws_user_pools_web_client_id,
              code,
              redirect_uri: awsconfig.oauth.redirectSignIn,
            }),
          }
        );

        if (response.ok) {
          const tokenData = await response.json();
          sessionStorage.setItem("idToken", tokenData.id_token);
          const userData = JSON.parse(atob(tokenData.id_token.split(".")[1]));
          setUser(userData);

          // Clear query string
          window.history.replaceState({}, document.title, "/");
        } else {
          console.error("Failed to fetch tokens:", response.statusText);
          handleSignIn();
        }
      } catch (error) {
        console.error("Error fetching tokens:", error);
        handleSignIn();
      }
    };

    const code = new URLSearchParams(window.location.search).get("code");
    if (code) {
      fetchTokens(code);
    } else {
      const storedToken = sessionStorage.getItem("idToken");
      if (storedToken) {
        const userData = JSON.parse(atob(storedToken.split(".")[1]));
        setUser(userData);
      } else {
        handleSignIn();
      }
    }
  }, []);

  return (
    <Router>
      {user ? (
        <>
          <NavigationBar signOut={handleSignOut} user={user} userData={user} />
          <Routes>
            <Route path="/" element={<AdminPage />} />
            <Route path="/profile/:userId" element={<ProfileDetails />} />
            <Route path="/overview/:userId" element={<Overview />} />
            <Route path="/iam/:userId" element={<IAM />} />
            <Route path="/detection/:userId" element={<Detection/>} />
            <Route path="/infrastrcutureprotection/:userId" element={<InfrastrcutureProtection/>} />
            <Route path="/dataprotection/:userId" element={<DataProtection/>} />
            <Route path="/incidentresponse/:userId" element={<IncidentResponse/>} />

          </Routes>
        </>
      ) : (
        <div className="loading-screen">
          <img src={logoGif} alt="Redirecting to login..." className="logo-animation" />
        </div>
      )}
    </Router>
  );
};

export default App;
