import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

const IncidentResponse = () => {
  const { userId } = useParams(); // Retrieve userId from URL
  const [incidentResponseData, setIncidentResponseData] = useState([]); // Incident Response data state
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch Incident Response data
    const fetchincidentResponseData = fetch(
      `https://yfzhsso2q0.execute-api.eu-west-1.amazonaws.com/api/getresponses?userId=${userId}&section=IRAutomation`
    )
      .then((response) => response.json())
      .catch((error) => {
        console.error("Error fetching Incident Response data:", error);
        return [];
      });

    // Fetch comments
    const fetchComments = fetch(
      `https://fnbm3rp2ya.execute-api.eu-west-1.amazonaws.com/api/getcomments?userId=${userId}&section=IRAutomation`
    )
      .then((response) => response.json())
      .catch((error) => {
        console.error("Error fetching comments:", error);
        return [];
      });

    // Wait for both responses and merge data
    Promise.all([fetchincidentResponseData, fetchComments]).then(([incidentResponse, commentsResponse]) => {
      const incidentResponseArray = Array.isArray(incidentResponse) ? incidentResponse : [];
      const commentsArray = Array.isArray(commentsResponse) ? commentsResponse : [];

      // Map comments to Incident Response data by questionId
      const mergedData = incidentResponseArray.map((incidentResponseItem) => {
        const matchingComment = commentsArray.find(
          (commentItem) => commentItem.questionId === incidentResponseItem.questionId
        );
        return {
          ...incidentResponseItem,
          comment: matchingComment ? matchingComment.comment : "No comment",
        };
      });

      setIncidentResponseData(mergedData);
      setLoading(false);
    });
  }, [userId]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (!incidentResponseData || incidentResponseData.length === 0) {
    return (
      <div className="text-center text-danger mt-5">
        <p>No Incident Response data available to display.</p>
      </div>
    );
  }

  return (
    <div className="container mt-5">
      <h4 className="text-center mb-4">Incident Response Section</h4>
      <div className="card shadow-sm">
        <div className="card-body">
          <h5 className="card-title text-primary">Incident Response Questions, Responses, and Comments</h5>
          <div className="table-responsive">
            <table className="table table-striped table-hover">
              <thead className="table-primary">
                <tr>
                  <th>Question ID</th>
                  <th>Question</th>
                  <th>Answer</th>
                  <th>Comment</th>
                  <th>Timestamp</th>
                </tr>
              </thead>
              <tbody>
                {incidentResponseData.map((item) => (
                  <tr key={item.sectionAndQuestionId}>
                    <td>{item.questionId}</td>
                    <td>{item.questionText}</td>
                    <td>
                      {item.answer === "1"
                        ? "Yes"
                        : item.answer === "0"
                        ? "No"
                        : item.answer === "NA"
                        ? "Not Applicable"
                        : "N/A"}
                    </td>
                    <td>{item.comment}</td>
                    <td>{new Date(item.timestamp).toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IncidentResponse;
