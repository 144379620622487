import React from 'react';
import { Card, Container, Row, Col } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import '../ComponentsCss/ProfileDetails.css';

const sections = [
  { name: 'Infrastructure Overview', path: 'overview' },
  { name: 'IAM', path: 'iam' },
  { name: 'Detection', path: 'detection' },
  { name: 'Infrastructure Protection', path: 'infrastrcutureprotection' },
  { name: 'Data Protection', path: 'dataprotection' },
  { name: 'Incident Response', path: 'incidentresponse' },
];

const ProfileDetails = () => {
  const { userId } = useParams(); // Retrieve user ID from URL
  const navigate = useNavigate(); // Hook for navigation

  const handleCardClick = (path) => {
    // Navigate to the section page
    navigate(`/${path}/${userId}`);
  };

  const handleDownloadPDF = () => {
    // Fetch the PDF URL from the backend
    fetch(`https://tdcxfr2s24.execute-api.eu-west-1.amazonaws.com/api/fetch-pdf?userId=${userId}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Error fetching PDF: ${response.statusText}`);
        }
        return response.text();
      })
      .then((pdfURL) => {
        // Trigger the file download
        const link = document.createElement('a');
        link.href = pdfURL;
        link.download = `User_${userId}_Report.pdf`;
        link.click();
      })
      .catch((error) => {
        console.error(error);
        alert('Failed to download the PDF. Please try again.');
      });
  };

  return (
    <Container className="profile-details-container">
      <Row className="user-details-row"></Row>
      <h3 className="mt-5">Modules</h3>
      <Row>
        {sections.map((section) => (
          <Col key={section.path} md={4} className="mb-4">
            <Card
              className="section-card"
              onClick={() => handleCardClick(section.path)} // Add onClick for navigation
              style={{ cursor: 'pointer' }} // Indicate clickability
            >
              <Card.Body>
                <Card.Title>{section.name}</Card.Title>
                <Card.Text>
                  Click to view details of {section.name} for the user.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
        {/* PDF Download Card */}
        <Col md={4} className="mb-4">
          <Card
            className="section-card"
            onClick={handleDownloadPDF} // Trigger PDF download
            style={{ cursor: 'pointer' }} // Indicate clickability
          >
            <Card.Body>
              <Card.Title>Download PDF</Card.Title>
              <Card.Text>
                Click to download the user's PDF report.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ProfileDetails;
