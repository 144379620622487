import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

const Overview = () => {
  const { userId } = useParams(); // Retrieve userId from URL
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`https://88zc0o1ocd.execute-api.eu-west-1.amazonaws.com/getuserdata/${userId}`)
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching overview data:", error);
        setLoading(false);
      });
  }, [userId]);
console.log(data)
  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (!data) {
    return (
      <div className="text-center text-danger mt-5">
        <p>No data available to display.</p>
      </div>
    );
  }

  return (
    <div className="container mt-5">
      <h4 className="text-center mb-4">Overview</h4>

      {/* Business Information */}
      <div className="card mb-4 shadow-sm">
        <div className="card-body">
          <h5 className="card-title text-primary">Business Information</h5>
          <p><strong>Business Type:</strong> {data.businessType || "N/A"}</p>
          <p><strong>Locations:</strong> {data.locations || "N/A"}</p>
          <p><strong>Countries:</strong> {data.countries.join(", ") || "N/A"}</p>
          <p><strong>Cities:</strong> {data.cities.join(", ") || "N/A"}</p>
          <p><strong>Users:</strong> {data.users || "N/A"}</p>
          <p><strong>Infrastructure:</strong> {data.infrastructuretype || "N/A"}</p>
        </div>
      </div>

      {/* Security Details */}
      <div className="card mb-4 shadow-sm">
        <div className="card-body">
          <h5 className="card-title text-primary">Security Details</h5>
          <p><strong>Compliance:</strong> {data.compliance || "N/A"}</p>
          <p><strong>Assets:</strong> {data.assets || "N/A"}</p>
          <p><strong>Security Services:</strong> {data.securityServices || "N/A"}</p>
          <p><strong>Security Event:</strong> {data.securityEvent || "N/A"}</p>
        </div>
      </div>

      {/* Questionnaire Responses */}
      <div className="card shadow-sm">
        <div className="card-body">
          <h5 className="card-title text-primary">Questionnaire Responses</h5>
          {data.questions && data.questions.length > 0 ? (
            <div className="table-responsive">
              <table className="table table-striped table-hover">
                <thead className="table-primary">
                  <tr>
                    <th>Question</th>
                    <th>Selected Option</th>
                    <th>Comment</th>
                  </tr>
                </thead>
                <tbody>
                  {data.questions.map((question) => (
                    <tr key={question.questionId}>
                      <td>{question.questionText}</td>
                      <td>{question.selectedOption}</td>
                      <td>{question.comment || "N/A"}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p className="text-muted">No questionnaire responses available.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Overview;
