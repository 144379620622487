import React, { useState, useEffect } from "react";
import { Row, Col, Card, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";



const AdminPage = () => {
  const [profiles, setProfiles] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const navigate = useNavigate();



  useEffect(() => {
    // Fetch profiles data from backend
    fetch("https://0v6905v839.execute-api.eu-west-1.amazonaws.com/newstage") // Replace with your backend endpoint
      .then((response) => response.json())
      .then((data) => {
        setProfiles(data);
        setLoading(false); // Data fetched, stop loading
      })
      .catch((error) => {
        console.error("Error fetching profiles:", error);
        setLoading(false); // Stop loading even if there's an error
      });
  }, []);

  const handleProfileClick = (userId) => {
    navigate(`/profile/${userId}`);
  };

  return (
    <>
      <Row className="g-0">
        <Col
          xs={12}
          md={12}
          className="p-4 section-container"
        >
          <h3 className="mb-4">Admin Portal - User Profiles</h3>

          {/* Show loader if data is still loading */}
          {loading ? (
            <div className="d-flex justify-content-center align-items-center">
              <Spinner animation="border" role="status" className="text-primary">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <Row>
              {profiles.map((profile) => (
                <Col key={profile.userId} xs={12} sm={6} md={4} lg={3} className="mb-4">
                  <Card
                    className="h-100 profile-card"
                    onClick={() => handleProfileClick(profile.userId)}
                    style={{ cursor: "pointer" }}
                  >
                    <Card.Body className="text-center">
                      {/* Placeholder for user profile image */}
                      <div className="profile-image mb-3">
                        <img
                          src={`https://via.placeholder.com/100?text=${profile.name.charAt(0)}`}
                          alt={`${profile.name}'s profile`}
                          className="rounded-circle"
                          style={{ width: "100px", height: "100px" }}
                        />
                      </div>
                      <Card.Title className="text-primary">{profile.name}</Card.Title>
                      <Card.Text className="text-muted">{profile.email}</Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          )}
        </Col>
      </Row>

     
    </>
  );
};

export default AdminPage;
