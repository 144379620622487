import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

const DataProtection = () => {
  const { userId } = useParams(); // Retrieve userId from URL
  const [dataProtectionData, setDataProtectionData] = useState([]); // Data Protection data state
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch Data Protection data
    const fetchDataProtectionData = fetch(
      `https://yfzhsso2q0.execute-api.eu-west-1.amazonaws.com/api/getresponses?userId=${userId}&section=DataProtection`
    )
      .then((response) => response.json())
      .catch((error) => {
        console.error("Error fetching Data Protection data:", error);
        return [];
      });

    // Fetch comments
    const fetchComments = fetch(
      `https://fnbm3rp2ya.execute-api.eu-west-1.amazonaws.com/api/getcomments?userId=${userId}&section=DataProtection`
    )
      .then((response) => response.json())
      .catch((error) => {
        console.error("Error fetching comments:", error);
        return [];
      });

    // Wait for both responses and merge data
    Promise.all([fetchDataProtectionData, fetchComments]).then(([dataProtectionResponse, commentsResponse]) => {
      const dataProtectionArray = Array.isArray(dataProtectionResponse) ? dataProtectionResponse : [];
      const commentsArray = Array.isArray(commentsResponse) ? commentsResponse : [];

      // Map comments to Data Protection data by questionId
      const mergedData = dataProtectionArray.map((dataProtectionItem) => {
        const matchingComment = commentsArray.find(
          (commentItem) => commentItem.questionId === dataProtectionItem.questionId
        );
        return {
          ...dataProtectionItem,
          comment: matchingComment ? matchingComment.comment : "No comment",
        };
      });

      setDataProtectionData(mergedData);
      setLoading(false);
    });
  }, [userId]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (!dataProtectionData || dataProtectionData.length === 0) {
    return (
      <div className="text-center text-danger mt-5">
        <p>No Data Protection data available to display.</p>
      </div>
    );
  }

  return (
    <div className="container mt-5">
      <h4 className="text-center mb-4">Data Protection Section</h4>
      <div className="card shadow-sm">
        <div className="card-body">
          <h5 className="card-title text-primary">Data Protection Questions, Responses, and Comments</h5>
          <div className="table-responsive">
            <table className="table table-striped table-hover">
              <thead className="table-primary">
                <tr>
                  <th>Question ID</th>
                  <th>Question</th>
                  <th>Answer</th>
                  <th>Comment</th>
                  <th>Timestamp</th>
                </tr>
              </thead>
              <tbody>
                {dataProtectionData.map((item) => (
                  <tr key={item.sectionAndQuestionId}>
                    <td>{item.questionId}</td>
                    <td>{item.questionText}</td>
                    <td>
                      {item.answer === "1"
                        ? "Yes"
                        : item.answer === "0"
                        ? "No"
                        : item.answer === "NA"
                        ? "Not Applicable"
                        : "N/A"}
                    </td>
                    <td>{item.comment}</td>
                    <td>{new Date(item.timestamp).toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataProtection;
