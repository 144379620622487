import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import '@aws-amplify/ui-react/styles.css';
import '../ComponentsCss/Navbar.css';
import { LogOut } from 'lucide-react';

// Import your logo image here
import logo from '../Assets/logo - Colored Background (1).png'; // Replace with the correct path to your logo file

const getRandomDarkColor = () => {
  let color;
  do {
    const letters = '0123456789ABCDEF';
    color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
  } while (parseInt(color.replace('#', ''), 16) > 0xffffff / 1.2);
  return color;
};

const Navbar = ({ signOut, user, userData }) => { // Accept userData as a prop
  const [userAttributes, setUserAttributes] = useState({ email: '', username: '' });
  const navigate = useNavigate();

  useEffect(() => {
    if (userData) {
      // Extract username and email from userData
      
      const email = userData.email || 'No Email';
      setUserAttributes({ email });
    }

    const avatarColor = getRandomDarkColor();
    document.documentElement.style.setProperty('--avatar-color', avatarColor);
  }, [userData]);

  // Call the passed-down signOut prop function
  const handleSignOut = async () => {
    try {
      await signOut(); // This uses the signOut function passed down as a prop
      sessionStorage.clear();
      localStorage.clear();
      navigate('/', { replace: true });
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  const capitalizeFirstLetter = (str) => {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  return (
    <>
      <nav className="flex w-full px-2 py-2 navbar-dash">
        {/* Logo on the left side */}
        <Link to="https://support.servqualit.com" className="navbar-brand">
          <img src={logo} alt="Logo" style={{ height: '30px', marginLeft: '40%' }} />
        </Link>
        <h3 className="heading-text-iam flex-grow">ServQual's Admin Page</h3>

        {/* Dropdown aligned to the right */}
        <div className="ml-auto mt-4 text-end drop">
          <Dropdown align="right">
            <Dropdown.Toggle id="user-dropdown" className="border-0 bg-transparent p-0 custom-toggle">
              <div className="flex items-center justify-center text-white bg-transparent mb-4">
                <span className="text-purple-600 font-bold text-lg avatar">
                  {userAttributes.email?.charAt(0).toUpperCase() || 'U'}
                </span>
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu className="dropdown-menu-right shadow-lg p-2">
              <div className="d-flex flex-row p-3">
                <span className="font-bold text-lg avatar">
                  {userAttributes.email?.charAt(0).toUpperCase() || 'U'}
                </span>
                <div className="d-flex flex-column navbar-icon">
                  <strong>
                    <span className="text-muted">
                      {capitalizeFirstLetter(userAttributes.email) || 'No Emil'}
                    </span>
                  </strong>
                 
                </div>
              </div>
              <Dropdown.Divider />
              <Dropdown.Item
                as="button"
                className="d-flex align-items-center text-danger"
                onClick={handleSignOut}
              >
                <LogOut className="me-2" />
                Log out
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
